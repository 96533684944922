<template lang="pug">
    div.footer-top.mt-50.mb-40
        div.container
            div.row
                div.col-lg-4
                    div.footer-single-widget
                        div.footer-logo.mb-40
                            a(href="index.html")
                                img(src="assets/images/logo/pos-circle-logo.jpg" alt="")
                        div.widget-body
                            p Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram.
                            div.widget-address.mt-30.mb-20
                                p
                                    strong Address:
                                    template 123 Main Street, Anytown, CA 12345 USA.
                                p
                                    strong Number Phone:
                                    template (800) 123 456 - (800) 123 456.
                                p
                                    strong Address Email:
                                    template support@circleshop.com
                        div.footer_social
                            ul.d-flex
                                li
                                    a.facebook(href="#")
                                        i.zmdi.zmdi-facebook
                                li
                                    a.twitter(href="#")
                                        i.zmdi.zmdi-twitter
                                li
                                    a.youtube(href="#")
                                        i.zmdi.zmdi-youtube
                                li
                                    a.google(href="#")
                                        i.zmdi.zmdi-google-plus
                                li
                                    a.linkedin(href="#")
                                        i.zmdi.zmdi-linkedin
                div.col-lg-8
                    div.row
                        div.col-lg-3.col-md-3.col-6
                            div.widgets_container
                                h6 Information
                                div.footer_menu
                                    ul
                                        li
                                            a(href="about.html") About Us
                                        li
                                            a(href="contact.html")  Contact us
                                        li
                                            a(href="#")  Privecy Policy
                                        li
                                            a(href="#") Terms & Conditions
                        div.col-lg-3.col-md-3.col-6
                            div.widgets_container
                                h6 My Account
                                div.footer_menu
                                    ul
                                        li
                                            a(href="#") My Account
                                        li
                                            a(href="#") Older History
                                        li
                                            a(href="#") Wishlist
                                        li
                                            a(href="#") Newslatter
                    div.row
                        div.col-lg-12
                            div.widget-box.mt-30
                                div.widget-single-box
                                    p
                                        strong Address:
                                    ul
                                        li
                                            a(href="#") Order
                                        li
                                            a(href="#") Affiliate
                                        li
                                            a(href="#") Marketing
                                        li
                                            a(href="#") Sourcing
                                        li
                                            a(href="#") Gadgets
                                        li
                                            a(href="#") Accessories
                                div.widget-single-box
                                    p
                                        strong Headphones:
                                    ul
                                        li
                                            a(href="#") Beats
                                        li
                                            a(href="#") Headphone Bose
                                        li
                                            a(href="#") Headphone Nocx
                                        li
                                            a(href="#") Wireless
                                        li
                                            a(href="#") Headphone
                                        li
                                            a(href="#") Headphone Mini
</template>
<script>
export default {
    name: 'FooterTopComponent'
}
</script>