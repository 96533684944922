<template lang="pug">
    footer.mt-30
        newsletter-component
        footer-top-component
        footer-bottom-component
</template>
<script>
import NewsletterComponent from './partials/NewsletterComponent'
import FooterTopComponent from './partials/FooterTopComponent'
import FooterBottomComponent from './partials/FooterBottomComponent'
export default {
    name: 'FooterComponent',
    components: {
        NewsletterComponent,
        FooterTopComponent,
        FooterBottomComponent
    }
}
</script>