<template lang="pug">
    div.slider_area.slider-one.mt-30
        div.single_slider
            img.img-fluid(src="assets/images/slider/slider-1.png" alt="")
            div.slider_content.color_one
                h5 The Hottest
                    br
                    template Trend
                h2 Laptop
                    br
                    template Tablets Outlet
                    div.pt-des
                        p
                            span 25%
                            template Starting at
                            span &nbsp; $340.00
                    a(href="shop.html") Shop Now
        div.single_slider
            img.img-fluid(src="assets/images/slider/slider-2.png" alt="")
            div.slider_content.color_two
                h5 The Hottest 
                    br
                    template Trend
                h2 Cellphone
                    br
                    template Smartphone Not 2
                div.pt-des
                    p
                        span 35%
                        template Starting at
                        span $120.00
                a(href="shop.html") Shop Now
</template>
<script>
export default {
    name: 'SingleSliderComponent'
}
</script>
