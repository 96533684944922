<template lang="pug">
    div.single-product-list.mb-20
        div.product-list-image
            a(href="product-details.html")
                img.img-fluid.block-one(src="assets/images/feature/feature-1.jpg" alt="")
                img.img-fluid.block-two(src="assets/images/feature/feature-6.jpg" alt="")
        div.product-caption
            div.product-name
                a(href="product-details.html") 4k Camcorder 2214c002
            div.rating
                span.yellow
                    i.fa.fa-star
                span.yellow
                    i.fa.fa-star
                span.yellow
                    i.fa.fa-star
                span.yellow
                    i.fa.fa-star
                span.yellow
                    i.fa.fa-star
            div.price-box
                span.regular-price $30.00
</template>
<script>
export default {
    name: 'SingleProductListComponent'
}
</script>