<template lang="pug">
    div.blog-post-container
        div.single_blog.mb-35
            div.blog_thumb.single-banner
                a(href="blog-fullwidth.html")
                    img.img-fluid(src="assets/images/blog/blog-post-1.jpg" alt="")
            div.blog_content
                h6
                    a(href="blog-fullwidth.html") This is Secound Post For XipBlog
                div.date_post.mt-15.mb-15
                    span 01 Jan 2020
                p.post-description Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been ...
        div.single_blog
            div.blog_thumb.single-banner
                a(href="blog-fullwidth.html")
                    img.img-fluid(src="assets/images/blog/blog-post-2.jpg" alt="")
            div.blog_content
                h6
                    a(href="blog-fullwidth.html") This is Secound Post For XipBlog
                div.date_post.mt-15.mb-15
                    span 01 Jan 2020
                p.post-description Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been ...
</template>
<script>
export default {
    name: 'BlogPostComponent'
}
</script>