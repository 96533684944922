<template lang="pug">
    div.banner-area.mt-10
        div.container
            div.row
                div.col-lg-4.col-md-4.col-12.text-center
                    div.single-banner.mt-40
                        a(href="#")
                            img.img-fluid(src="assets/images/banner/banner-2.jpg" alt="")
                div.col-lg-4.col-md-4.col-12.text-center
                    div.single-banner.mt-40
                        a(href="#")
                            img.img-fluid(src="assets/images/banner/banner-3.jpg" alt="")
                div.col-lg-4.col-md-4.col-12.text-center
                    div.single-banner.mt-40
                        a(href="#")
                            img.img-fluid(src="assets/images/banner/banner-4.jpg" alt="")
</template>
<script>
export default {
    name: 'BannerAreaComponent'
}
</script>