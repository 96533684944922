<template lang="pug">
    div.header-menu.header-bottom-area.theme-bg.sticker
        div.container
            div.row
                div.col-lg-3
                    div.categoryes-menu-bar
                        div.categoryes-menu-btn.category-toggle
                            div.float-left
                                a(href="#") {{$t('links.all_categories')}}
                            div.float-right
                                i.fa.fa-bars
                        nav.categorye-menus.category-dropdown
                            ul.categories-expand
                                template(v-for="category in $store.getters.categories")
                                    li.categories-hover-right(v-if="category.children.length > 0")
                                        a(href="#") {{category.nombre}}
                                            i.fa.fa-angle-right.float-right(v-if="category.children.length > 0")
                                        ul.cat-submenu.category-mega
                                            li.cat-mega-title(v-for="level2 in category.children" :key="level2.id")
                                                a(href="#") {{level2.nombre}}
                                                ul(v-if="level2.children.length > 0")
                                                    li(v-for="level3 in level2.children" :key="level3.id")
                                                        a(href="#") {{level3.nombre}}
                                    li(v-if="category.children == 0")
                                        a(href="#") {{category.nombre}}
                div.col-lg-9
                    div.header-menu.add-sticky
                        div.sticky-container
                            div.logo
                                router-link(to="/")
                                    img.img-fluid(src="assets/images/logo/pos-circle-logo.jpg" alt="")
                            nav.main-menu
                                ul
                                    li
                                        router-link(to="/") {{$t('links.home')}}
                                    li
                                        router-link(to="/shop?category_id=0") {{$t('links.shop')}}
                                            i.fa.fa-angle-down
                                        ul.dropdown.dropdown-width
                                            li(v-for="category in $store.getters.categories" :key="category.id")
                                                router-link(:to="'/shop?category_id=' + category.id") {{category.nombre}}
                                    li
                                        router-link(to="/about") {{$t('links.about_us')}}
                                    li
                                        router-link(to="/contact") {{$t('links.contact_us')}}
</template>
<script>
export default {
    name: 'BottomHeaderComponent'
}
</script>