<template lang="pug">
    div.shipping-section
        div.container
            div.row
                div.col-lg-3
                div.col-lg-9
                    div.row.all-shipping
                        div.col-lg-4.col-md-4.col-12
                            div.single-shipping
                                div.block-wrapper
                                    div.shipping-content
                                        h5.ship-title Free Delivery
                                        p Free shipping on all order
                        div.col-lg-4.col-md-4.col-12
                            div.single-shipping
                                div.block-wrapper2
                                    div.shipping-content
                                        h5.ship-title Online Support 24/7
                                        p Free shipping on all order
                        div.col-lg-4.col-md-4.col-12
                            div.single-shipping.single-shipping-last
                                div.block-wrapper3
                                    div.shipping-content
                                        h5.ship-title Money Return
                                        p Free shipping on all order
</template>
<script>
export default {
    name: 'ShippingSectionComponent'
}
</script>