<template lang="pug">
    div.product-area.mt-50
        div.container
            div.row
                div.col-lg-12
                    ul.nav.nav-tabs.theme-tabs(role="tablist")
                        li.nav-item
                            a.nav-link.active(id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="one" aria-selected="true") New Arrival
                        li.nav-item
                            a.nav-link(id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="two" aria-selected="false") Bestseller
                        li.nav-item
                            a.nav-link(id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="three" aria-selected="false") Featured Product
                    div.tab-content
                        div.tab-pane.active(id="one" role="tabpanel" aria-labelledby="one-tab")
                            div.product-thing.slick-custom.slick-custom-default
                                item-product-component
                        div.tab-pane(id="two" role="tabpanel" aria-labelledby="two-tab")
                            div.product-thing.slick-custom.slick-custom-default
                                item-product-component
                        div.tab-pane(id="three" role="tabpanel" aria-labelledby="three-tab")
                            div.product-thing.slick-custom.slick-custom-default
                                item-product-component
</template>
<script>
import ItemProductComponent from './partials/ItemProductComponent'
export default {
    name: 'ProductAreaComponent',
    components: {
        ItemProductComponent,
    }
}
</script>