<template lang="pug">
    div.latest-post-area.mt-50
        div.container
            div.row
                div.col-lg-6.col-12
                    div.block-title
                        h6 From Our blog
                    div.blog-post-carousel.slick-custom.slick-custom-default.nav-top
                        blog-post-component
                div.col-lg-6.col-12
                    div.block-title
                        h6 What Client Say
                    div.testimonial-carousel.slick-custom.slick-custom-default.nav-top
                        testimonial-component
</template>
<script>
import BlogPostComponent from './partials/BlogPostComponent'
import TestimonialComponent from './partials/TestimonialComponent'
export default {
    name: 'LatestPostAreaComponent',
    components: {
        BlogPostComponent,
        TestimonialComponent
    }
}
</script>