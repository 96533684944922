<template lang="pug">
    div.feature-category-area
        div.container
            div.row
                div.col-lg-4.col-md-6.mt-50
                    div.block-title
                        h6 New Products
                    div.feature-carousel.slick-custom.slick-custom-default.nav-top
                        div.product-list-content
                            single-product-list-component
                div.col-lg-4.col-md-6.mt-50
                    div.block-title
                        h6 Featured Products
                    div.feature-carousel.slick-custom.slick-custom-default.nav-top
                        div.product-list-content
                            single-product-list-component
                div.col-lg-4.col-md-6.mt-50
                    div.block-title
                        h6 Bestseller Products
                    div.feature-carousel.slick-custom.slick-custom-default.nav-top
                        div.product-list-content
                            single-product-list-component
</template>
<script>
import SingleProductListComponent from './partials/SingleProductListComponent'
export default {
    name: 'FeatureAreaComponent',
    components: {
        SingleProductListComponent
    }
}
</script>