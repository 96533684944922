<template lang="pug">
    div.footer-bottom
        div.container
            div.row.align-items-center
                div.col-lg-5.col-md-12.col-12
                    div.footer-bottom-content
                        div.footer-copyright
                            p © 2021 Copyright
                                strong &nbsp; CIRCLESHOP
                                template &nbsp; Made With 
                                i.fa.fa-heart.text-danger
                                template &nbsp; by 
                                a(href="https://hasthemes.com/")
                                    strong HasThemes
                div.col-lg-7.col-md-12.col-12
                    div.payment
                        a(href="#")
                            img.img-fluid(src="assets/images/payment/footerend.png" alt="")
</template>
<script>
export default {
    name: 'FooterBottomComponent'
}
</script>