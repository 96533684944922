<template lang="pug">
    div.item-product
        div.product-thumb
            a(href="product-details.html")
                img.img-fluid(src="assets/images/product/product-1.jpg" alt="")
            div.box-label
                div.label-product-new
                    span New
            div.action-link
                a.quick-view.same-link(href="#" title="Quick view" data-toggle="modal" data-target="#modal_box" data-original-title="quick view")
                    i.zmdi.zmdi-eye.zmdi-hc-fw
                a.compare-add.same-link(href="compare.html" title="Add to compare")
                    i.zmdi.zmdi-refresh-alt
                a.wishlist-add.same-link(href="wishlist.html" title="Add to wishlist")
                    i.zmdi.zmdi-favorite-outline.zmdi-hc-fw
        div.product-caption
            div.product-name
                a(href="product-details.html") Natus erro at congue massa commodo sit dignissim
            div.rating
                span.yellow
                    i.fa.fa-star
                span.yellow
                    i.fa.fa-star
                span.yellow
                    i.fa.fa-star
                span.yellow
                    i.fa.fa-star
                span.yellow
                    i.fa.fa-star
            div.price-box
                span.regular-price $30.00
            div.cart
                div.add-to-cart
                    a.cart-plus(href="shopping-cart.html" title="Add to cart")
                        i.zmdi.zmdi-shopping-cart-plus.zmdi-hc-fw
</template>
<script>
export default {
    name: 'ItemProductComponent'
}
</script>