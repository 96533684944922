<template lang="pug">
    div.header-top.full-border
        div.container
            div.row
                div.col-lg-4.col-12
                    div.header-top-left
                        p
                            span {{ $t('labels.header_customer_service') }} 
                            template (800) 123 456 789
                div.col-lg-8.col-12
                    div.box-right
                        ul
                            li.settings
                                a(href="#") {{ $t('labels.header_compare') }} (0)
                            li.settings
                                a.drop-toggle(href="#")
                                    span {{ $t('labels.header_mxn') }}
                                    i.fa.fa-angle-down
                                ul.box-dropdown.drop-dropdown
                                    li
                                        a(href="#") {{ $t('labels.header_eur') }}
                                    li
                                        a(href="#") {{ $t('labels.header_mxn') }}
                                    li
                                        a(href="#") {{ $t('labels.header_usd') }}
                            li.settings
                                a.drop-toggle(href="#")
                                    template {{ $t('labels.header_spanish') }}
                                    i.fa.fa-angle-down
                                ul.box-dropdown.drop-dropdown
                                    li
                                        a(href="#") {{ $t('labels.header_spanish') }}
                                    li
                                        a(href="#") {{ $t('labels.header_english') }}
                            li.settings
                                a.drop-toggle(href="#")
                                    template {{ $t('labels.header_my_account') }}
                                    i.fa.fa-angle-down
                                ul.box-dropdown.drop-dropdown
                                    li
                                        a(href="#") {{ $t('labels.header_my_account') }}
                                    li
                                        a(href="#") {{ $t('labels.header_checkout') }}
                                    li
                                        a(href="#") {{ $t('labels.header_sign_in') }}
</template>
<script>
export default {
    name: 'TopheaderComponent'
}
</script>