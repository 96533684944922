<template lang="pug">
    div.sales-offer-area.mb-45.mt-10
        div.container
            div.row
                div.col-lg-9.col-12
                    div.product-offer-slider.slick-custom-default
                        div.flash-single-item
                            div.product-item
                                span.offer-bar
                                    img(src="assets/images/product/sale-offer.png" alt="")
                                div.product-thumb
                                    a(href="product-details.html")
                                        img.img-fluid(src="assets/images/product/offer/product-9.jpg" alt="")
                                    div.box-label
                                        div.label-product-discount
                                            span -20%
                                div.product-caption
                                    div.product-name.mb-20
                                        a(href="product-details.html")
                                            h6 Natus erro at congue massa commodo sit dignissim
                                    div.rating
                                        span.yellow
                                            i.fa.fa-star
                                        span.yellow
                                            i.fa.fa-star
                                        span.yellow
                                            i.fa.fa-star
                                        span.yellow
                                            i.fa.fa-star
                                        span.yellow
                                            i.fa.fa-star
                                    div.price-box.mt-15.mb-15
                                        span.regular-price $30.00
                                        span.old-price
                                            del $35.50
                                    div.product-pre-content.mb-30
                                        p The S-Series Full HD TCL Roku TV puts all your entertainment favorites in one place, allowing seamless access to...
                                    div.countdown
                                        div.box-countdown
                                            div.title-countdown
                                                h6.mb-20 Hurry Up! Offer ends in:
                                            div(data-countdown="2021/12/24")
                div.col-lg-3.col-12.text-center
                    div.single-banner.mt-40
                        a(href="#")
                            img.img-fluid(src="assets/images/banner/banner-1.jpg" alt="")
</template>
<script>
export default {
    name: 'SalesOfferComponent',
}
</script>