<template lang="pug">
    div.single_testimonial.text-center
        p Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feuga
        img.img-fluid(src="assets/images/testimonial/testimonial-1.png" alt="")
        span.name Kathy Young
        span.job_title CEO of SunPark
        div.rating
            span.yellow
                i.fa.fa-star
            span.yellow
                i.fa.fa-star
            span.yellow
                i.fa.fa-star
            span.yellow
                i.fa.fa-star
            span.yellow
                i.fa.fa-star
</template>
<script>
export default {
    name: 'TestimonialComponent'
}
</script>