<template lang="pug">
    div.header-middle.space-40
        div.container
            div.row.align-items-center
                div.col-lg-3.col-6
                    div.logo
                        router-link(to="/")
                            img.img-fluid(src="assets/images/logo/pos-circle-logo.jpg" alt="")
                div.col-lg-9.col-6
                    div.header-middle-inner
                        div.search-container
                            form(action="#")
                                div.top-cat
                                    select.select-option(name="category_id" id="category_id")
                                        option(value="0") {{$t('labels.option_all_categories')}}
                                        option(:value="category.id" v-for="category in categories" :key="category.id") - - {{category.name}}
                                div.search_box
                                    input.header-search(:placeholder="$t('placeholders.header_search')" type="text")
                                    button.header-search-button(type="submit") {{$t('buttons.header_search_button')}}
                        div.blockcart
                            a.drop-toggle(href="#")
                                img.img-fluid(src="assets/images/cart/cart.png" alt="")
                                template
                                    span.my-cart {{$t('labels.cart')}}
                                    span.count 2
                                    span.total-item $200.00
                            div.cart-dropdown.drop-dropdown
                                ul
                                    li.mini-cart-details
                                        div.innr-crt-img
                                            img(src="assets/images/cart/ear-headphones.jpg" alt="")
                                            template
                                                span 1x
                                        div.innr-crt-content
                                            span.product-name
                                                a(href="#") SonicFuel Wireless Over-Ear Headphones
                                            span.product-price $32.30
                                            span.product-size Size:  S
                                    li.mini-cart-details.mb-30
                                        div.innr-crt-img
                                            img(src="assets/images/cart/720-degree-cameras-dual.jpg" alt="")
                                            template
                                                span 1x
                                        div.innr-crt-content
                                            span.product-name
                                                a(href="#") 720 Degree Panoramic HD 360..
                                            span.product-price $29.00
                                            span.product-size Dimension:  40cm X 60cm
                                    li
                                        span.subtotal-text Subtotal
                                        span.subtotal-price $61.30
                                    li
                                        span.subtotal-text Shipping
                                        span.subtotal-price $40.20
                                    li
                                        span.subtotal-text Taxes
                                        span.subtotal-price $10.07
                                    li
                                        span.subtotal-text Total
                                        span.subtotal-price $111.57
                                div.checkout-cart
                                    a(href="checkout.html") Checkout
</template>
<script>
export default {
    name: 'MiddleHeaderComponent',
    data() {
        return {
            categories: []
        }
    },
    async mounted() {
        this.openLoading()
        await this.getCategories()
        window.Swal.close()
        this.$store.commit('setCategories', this.categories)
    }
}
</script>