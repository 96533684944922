<template lang="pug">
    div
        div.offcanvas_overlay
        div.offcanvas_menu
            div.container
                div.row
                    div.col-12
                        div.canvas_open
                            a(href="#")
                                i.zmdi.zmdi-menu
                        div.offcanvas_menu_wrapper
                            div.canvas_close
                                a(href="#")
                                    i.zmdi.zmdi-close
                            div.welcome_text.text-center.mb-10
                                p
                                    span Customer Service:
                                    template (800) 123 456 789
                            div.box-right.text-center.mb-20
                                ul
                                    li.settings
                                        a.drop-toggle(href="#")
                                            span USD $
                                            i.fa.fa-angle-down
                                        ul.box-dropdown.drop-dropdown
                                            li
                                                a(href="#") EUR €
                                            li
                                                a(href="#") USD $
                                    li.settings
                                        a.drop-toggle(href="#")
                                            img(src="assets/images/cuntry/1.jpg" alt="")
                                            template English
                                            i.fa.fa-angle-down
                                        ul.box-dropdown.drop-dropdown
                                            li
                                                a(href="#")
                                                    img(src="assets/images/cuntry/1.jpg" alt="")
                                                    template English
                                            li
                                                a(href="#")
                                                    img(src="assets/images/cuntry/2.jpg" alt="")
                                                    template Francies
                                    li.settings
                                        a(href="compare.html") Compare (2)
                            div.widget-body
                                div.widget-address.text-center.mb-20
                                    p
                                        strong ADDRESS
                                    p 123 Main Street, Anytown
                                        br 
                                        template CA 12345 USA.
                                    p (800) 123 456 - (800) 123 456.
                            div.offcanvas-search-container.mb-40
                                form(action="#")
                                    div.search_box
                                        input(placeholder="Search entire store here ..." type="text")
                                        button(type="submit") Search
                            div.offcanvas_menu_cover.text-left
                                ul.offcanvas_main_menu
                                    li.menu-item-has-children.active
                                        router-link(to="/") Home
                                        ul.sub-menu
                                            li
                                                a(href="index.html") Home 1
                                            li
                                                a(href="index-2.html") Home 2
                                            li
                                                a(href="index-3.html") Home 3
                                            li
                                                a(href="index-4.html") Home 4
                                    li.menu-item-has-children
                                        a(href="#") Shop
                                        ul.sub-menu
                                            li.menu-item-has-children
                                                a(href="#") Shop Layouts
                                                ul.sub-menu
                                                    li
                                                        a(href="shop.html") Shop
                                                    li
                                                        a(href="shop-fullwidth.html") Full Width
                                                    li
                                                        a(href="shop-fullwidth-list.html") Full Width list
                                                    li
                                                        a(href="shop-right-sidebar.html") Right Sidebar 
                                                    li
                                                        a(href="shop-right-sidebar-list.html") Right Sidebar list
                                                    li
                                                        a(href="shop-list.html") List View
                                            li.menu-item-has-children
                                                a(href="#") other Pages
                                                ul.sub-menu
                                                    li
                                                        a(href="shopping-cart.html") Cart
                                                    li
                                                        a(href="wishlist.html") Wishlist
                                                    li
                                                        a(href="checkout.html") Checkout
                                                    li
                                                        a(href="my-account.html") My Account
                                                    li
                                                        a(href="faq.html") FAQs
                                                    li
                                                        a(href="404.html") Error 404
                                            li.menu-item-has-children
                                                a(href="#") Product Types
                                                ul.sub-menu
                                                    li
                                                        a(href="product-details.html") Product details
                                                    li
                                                        a(href="product-details-external.html") Product external
                                                    li
                                                        a(href="product-details-grouped.html") Product grouped
                                                    li
                                                        a(href="product-details-variable.html") Product variable
                                                    li
                                                        a(href="product-details-bottomtab.html") Tab style - 1
                                                    li
                                                        a(href="product-details-lefttab.html") Tab style - 2
                                                    li
                                                        a(href="product-details-righttab.html") Tab style - 3
                                                    li
                                                        a(href="product-details-stickyleft.html") Product sticky left
                                                    li
                                                        a(href="product-details-stickyright.html") Product sticky right
                                                    li
                                                        a(href="product-details-galleryleft.html") Product leftside Gallery
                                                    li
                                                        a(href="product-details-galleryright.html") Product rightside Gallery
                                                    li
                                                        a(href="product-details-sliderbox.html") Product Sliderbox
                                    li.menu-item-has-children
                                        a(href="#") blog
                                        ul.sub-menu
                                            li.menu-item-has-children
                                                a(href="#") Blog Grid
                                                ul.sub-menu
                                                    li
                                                        a(href="blog-grid-right-sidebar.html") Right Sidebar
                                                    li
                                                        a(href="blog-grid-left-sidebar.html") Left Sidebar
                                                    li
                                                        a(href="blog-grid-fullwidth.html") Full Width
                                            li.menu-item-has-children
                                                a(href="#") Blog List
                                                ul.sub-menu
                                                    li
                                                        a(href="blog-list-right-sidebar.html") Right Sidebar
                                                    li
                                                        a(href="blog-list-left-sidebar.html") Left Sidebar
                                                    li
                                                        a(href="blog-list-fullwidth.html") Full Width
                                            li.menu-item-has-children
                                                a(href="#") Blog Single Post
                                                ul.sub-menu
                                                    li
                                                        a(href="blog-details.html") Right Sidebar
                                                    li
                                                        a(href="blog-details-left-sidebar.html") Left Sidebar
                                                    li
                                                        a(href="blog-details-fullwidth.html") Full Width
                                            li.menu-item-has-children
                                                a(href="#") Standard Blog
                                                ul.sub-menu
                                                    li
                                                        a(href="blog-right-sidebar.html") Right Sidebar
                                                    li
                                                        a(href="blog-left-sidebar.html") Left Sidebar
                                                    li
                                                        a(href="blog-fullwidth.html") Full Width
                                    li.menu-item-has-children
                                        a(href="#") pages
                                        ul.sub-menu
                                            li
                                                a(href="about.html") About Us
                                            li
                                                a(href="shop.html") Shop
                                            li
                                                a(href="product-details.html") Product
                                            li
                                                a(href="shopping-cart.html") Shopping Cart
                                            li
                                                a(href="wishlist.html") Wishlist
                                            li
                                                a(href="checkout.html") Checkout
                                            li
                                                a(href="compare.html") Compare
                                            li
                                                a(href="contact.html") Contact Us
                                            li
                                                a(href="faq.html") Frequently Question
                                            li
                                                a(href="my-account.html") My Account
                                            li
                                                a(href="login.html") Login
                                            li
                                                a(href="register.html") Register
                                            li
                                                a(href="404.html") 404 Error
                                    li.menu-item-has-children
                                        a(href="about.html") about Us
                                    li.menu-item-has-children
                                        a(href="contact.html") Contact Us
                            div.offcanvas_footer
                                span
                                    a(href="#")
                                        i.fa.fa-envelope-o
                                        template info@yourdomain.com
                                div.footer_social
                                    ul.d-flex
                                        li
                                            a.facebook(href="#")
                                                i.zmdi.zmdi-facebook
                                        li
                                            a.twitter(href="#")
                                                i.zmdi.zmdi-twitter
                                        li
                                            a.youtube(href="#")
                                                i.zmdi.zmdi-youtube
                                        li
                                            a.google(href="#")
                                                i.zmdi.zmdi-google-plus
                                        li
                                            a.linkedin(href="#")
                                                i.zmdi.zmdi-linkedin
        header-component
        slider-component
        shipping-section-component
        product-area-component
        sales-offer-component
        product-category-area-component
        banner-area-component
        feature-area-component
        brand-area-component
        latest-post-area-component
        footer-component
        a.scroll-to-top(href="#")
            i.fa.fa-angle-up
</template>
<script>
// @ is an alias to /src
import HeaderComponent from '../components/ecommerce/circleshop/HeaderComponent'
import SliderComponent from '../components/ecommerce/circleshop/SliderComponent'
import ShippingSectionComponent from '../components/ecommerce/circleshop/ShippingSectionComponent'
import ProductAreaComponent from '../components/ecommerce/circleshop/ProductAreaComponent'
import SalesOfferComponent from '../components/ecommerce/circleshop/SalesOfferComponent'
import ProductCategoryAreaComponent from '../components/ecommerce/circleshop/ProductCategoryAreaComponent'
import BannerAreaComponent from '../components/ecommerce/circleshop/BannerAreaComponent'
import FeatureAreaComponent from '../components/ecommerce/circleshop/FeatureAreaComponent'
import BrandAreaComponent from '../components/ecommerce/circleshop/BrandAreaComponent'
import LatestPostAreaComponent from '../components/ecommerce/circleshop/LatestPostAreaComponent'
import FooterComponent from '../components/ecommerce/circleshop/FooterComponent'
export default {
    name: 'Home',
    components: {
        HeaderComponent,
        SliderComponent,
        ShippingSectionComponent,
        ProductAreaComponent,
        SalesOfferComponent,
        ProductCategoryAreaComponent,
        BannerAreaComponent,
        FeatureAreaComponent,
        BrandAreaComponent,
        LatestPostAreaComponent,
        FooterComponent
    },
    mounted() {
        const script = document.createElement('script')
        script.src = 'assets/js/main.js'
        document.body.appendChild(script)
    }
}
</script>
<style src="../assets/general/css/vendor/bootstrap.min.css"></style>
<style src="../assets/general/css/vendor/font-awesome.min.css"></style>
<style src="../assets/general/css/vendor/material-design-iconic-font.min.css"></style>
<style src="../assets/general/css/vendor/animate.min.css"></style>
<style src="../assets/general/css/plugins/magnific-popup.css"></style>
<style src="../assets/general/css/plugins/jquery-ui.min.css"></style>
<style src="../assets/general/css/plugins/plugins.css"></style>
<style src="../assets/general/css/style.css"></style>
<style>
    
</style>
