<template lang="pug">
    div.header-area
        topheader-component
        middle-header-component
        bottom-header-component
</template>
<script>
import TopheaderComponent from './partials/TopheaderComponent'
import MiddleHeaderComponent from './partials/MiddleHeaderComponent'
import BottomHeaderComponent from './partials/BottomHeaderComponent'
export default {
    name: 'HeaderComponent',
    components: {
        TopheaderComponent,
        MiddleHeaderComponent,
        BottomHeaderComponent
    }
}
</script>