<template lang="pug">
    div.slider_section.mb-60
        div.container
            div.row
                div.col-lg-3
                div.col-lg-9
                    single-slider-component
</template>
<script>
import SingleSliderComponent from './partials/SingleSliderComponent'
export default {
    name: 'SliderComponent',
    components: {
        SingleSliderComponent,
    }
}
</script>