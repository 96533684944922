<template lang="pug">
    div.newsletter-group
        div.container
            div.row.align-items-center
                div.col-lg-6.col-12
                    div.newsletter-inner.d-flex.align-items-center
                        i.fa.fa-envelope-open-o
                        div.newsletter-title
                            h1.sign-title Sign Up For Our Newsletter
                            p Get e-mail updates about our latest shop and special offers.
                div.col-lg-6.col-12
                    div.newsletter-box
                        form.mc-form(id="mc-form")
                            input.email-box(type="email" id="mc-email" placeholder="enter your email" name="EMAIL")
                            button.newsletter-btn(id="mc-submit" type="submit") Subscribe
                        div.mailchimp-alerts.text-centre
                            div.mailchimp-submitting
                            div.mailchimp-success.text-success
                            div.mailchimp-error.text-danger
</template>
<script>
export default {
    name: 'NewsletterComponent'
}
</script>