<template lang="pug">
    div.product-category-area
        div.container
            div.row
                div.col-lg-12
                    ul.nav.nav-tabs.category-tabs
                        li.nav-item
                            a.nav-link.active(id="four-tab" data-toggle="tab" href="#four")
                                span
                                    img.img-fluid(src="assets/images/category/thumb-1.png" alt="")
                                span Computer - Laptop
                        li.nav-item
                            a.nav-link(id="five-tab" data-toggle="tab" href="#five")
                                span
                                    img.img-fluid(src="assets/images/category/thumb-2.png" alt="")
                                span Electronics
                    div.tab-content
                        div.product-thing-tab.slick-custom-default.tab-pane.fade.show.active(id="four")
                            item-product-component
                        div.product-thing-tab.slick-custom-default.tab-pane.fade(id="five")
                            item-product-component
</template>
<script>
import ItemProductComponent from './partials/ItemProductComponent'
export default {
    name: 'ProductCategoryAreaComponent',
    components: {
        ItemProductComponent,
    }
}
</script>