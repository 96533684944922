<template lang="pug">
    div.brand-area.mt-35
        div.container
            div.row
                div.col-lg-12.text-center
                    div.brand-logo
                        div.single-brand
                            a(href="#")
                                img.img-fluid(src="assets/images/brand/3.jpg" alt="")
                        div.single-brand
                            a(href="#")
                                img.img-fluid(src="assets/images/brand/2.jpg" alt="")
                        div.single-brand
                            a(href="#")
                                img.img-fluid(src="assets/images/brand/1.jpg" alt="")
                        div.single-brand
                            a(href="#")
                                img.img-fluid(src="assets/images/brand/4.jpg" alt="")
                        div.single-brand
                            a(href="#")
                                img.img-fluid(src="assets/images/brand/5.jpg" alt="")
</template>
<script>
export default {
    name: 'BrandAreaComponent',
}
</script>